import React from 'react';

const InfosPage = (props) => {
  return (
    <>
      <div className="u-wrapper">
        <div className="c-infos-page">
          <h2 className="c-infos-page__ttl o-ttl o-ttl--50">{props.titlePage}</h2>
          <p className="o-headline">{props.text}</p>
        </div>
      </div>
    </>
  )
}

export default InfosPage;
