import React from "react"
import PropTypes from 'prop-types';

const BannerTopo = (props) => {
  return (
    <>
      <div className="c-banner">
        <img className="c-banner__img" src={props.image} alt={props.title ? props.title : ""} />
      </div>
    </>
  )
}

BannerTopo.propTypes = {
  image: PropTypes.string.isRequired,
};

export default BannerTopo
