import React, {useState} from "react"
import Program from 'components/pages/program';
import Layout from 'components/layout/layout';
import { Router } from "@reach/router"

const Programas = () => {

  const [api, setApi] = useState({});

  const init = (apiData) => {
    setApi(apiData);
  }
  
  return (
    <Layout loadedCallback={init} pageTitle="Programas" pageDescription="Conheça os programas Stone Co.">
      {api && api.constructor === Object && Object.keys(api).length !== 0 && (
        <Router basepath="programas">
          <Program apiData={api} path="/:program" />
        </Router>
      )}
    </Layout>
  )
}

export default Programas
