import React from "react";
import CardsCarousel from 'components/cardCarousel';
import Title from 'components/title';

const Programs = props => {

  return (
    <>
      <div className="u-wrapper u-wrapper--mb">
        <div className="u-text-center">
          <Title content="Nossos <strong>programas</strong>" />
        </div>
      </div>
      <CardsCarousel hasLink={true} path="/programas/" cards={props.programs} />
    </>
  )
}

export default Programs
