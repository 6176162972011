import React, {useEffect, useState} from "react"
import BannerTopo from "components/bannerTopo"
import InfosPage from "components/InfosPage"
import Testimonials from "components/testimonials"
import Embed from 'components/embed'
import {getBucketUrl} from '../../../service'
import Programs from "components/programs"
import { Helmet } from 'react-helmet'
import { navigate } from "gatsby"

const Program = props => {

  const [program, setProgram] = useState({});

  const [api, setApi] = useState({});

  const init = () => {
    setApi(props.apiData);

    let checkProgram = null;

    if (props.apiData.collections.programs && Array.isArray(props.apiData.collections.programs)) {
      props.apiData.collections.programs.forEach(element => {
        if (element.slug === props.program && (element.link === null || element.link === ""))
        checkProgram = element;
      });
    }

    if (!checkProgram) {
      navigate("/");
    } else {
      setProgram(checkProgram);
    }
  }

  useEffect(() => {
    init();
  }, [props.program])

  return (
    <>
      {Object.keys(program).length && (
        <>
          <Helmet>
            <title>{`${program.title} | Programa | Jornada Stone`}</title>
          </Helmet>
          <InfosPage titlePage={program.title} text={program.subtitle} />
          <BannerTopo image={getBucketUrl(program.image.path)} />
          <div className="u-wrapper u-wrapper--mb">
            <Embed src={program.embed} />
          </div>
          {program.testimonials && Array.isArray(program.testimonials) && program.testimonials.length > 0 && (
            <Testimonials testimonials={program.testimonials.map(item => item.value)} />
          )}
          <Programs programs={api.collections.programs} />
        </>
      )}
    </>
  )
}

export default Program
