import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import {getBucketUrl} from '../../service'

const Testimonials = (props) => {
  const [infosFiltered, setInfosFiltered] = useState([])

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    className: 'c-testimonials-slider',
    dots: false,
    infinite: true,
    slidesToShow: 1
  }

  useEffect(() => {
    setInfosFiltered(props.testimonials)
  }, [props.testimonials])

  return (
    <>
      <div className="c-testimonials">
        <div className="u-wrapper u-wrapper--1134">
          <h2 className="c-testimonials__ttl o-ttl o-ttl--40 u-text-center">Depoimentos</h2>
          {infosFiltered.length > 0 && (
            <Slider {...settings}>
              {infosFiltered.map((res, index) => (
                <div className="c-testimonials__slide" data-index={index} key={index}>
                  <div className="c-testimonials__item">
                    <div className="c-testimonials__person">
                      <img className="c-testimonials__photo" src={getBucketUrl(res.image.path)} alt={res.name}/>
                      <span className="c-testimonials__name">{res.name}</span>
                    </div>
                    <div className="c-testimonials__content">
                      <p>{res.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </>
  )
}

export default Testimonials
